@import url("https://cdnjs.cloudflare.com/ajax/libs/material-components-web/4.0.0/material-components-web.min.css");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.mdl-data-table {
  border: none !important;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 44px;
  z-index: 1;
  position: relative;
}
div.dataTables_scrollHeadInner {
  width: 100% !important;
}
div.dataTables_scrollBody table {
  margin-top: 0;
}
div.dataTables_scrollBody table thead {
  display: none;
}
div.dataTables_wrapper {
  width: 100% !important;
  overflow: auto;
  padding-bottom: 90px;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
  right: 40px;
  top: 14px;
  position: absolute;
  filter: grayscale(1);
  z-index: 9999;
}
div.dataTables_wrapper div.dataTables_filter label {
  position: relative;
  padding-right: 13px;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  width: 100%;
  padding-left: 30px;
  font-size: 16px;
}
div.dataTables_wrapper div.dataTables_filter input:focus {
  outline-offset: 0;
  outline: none;
}
div.dataTables_wrapper div.dataTables_filter label .search-icon {
  position: absolute;
  left: 8px;
  top: -3px;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 10px;
  white-space: nowrap;
  width: 200px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 12px;
  color: #aaa;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 95px;
  left: 0%;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: calc(100% - 90px);
  text-align: center;
  z-index: 9;
}
div.dataTables_wrapper div.dataTables_processing span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  height: calc(100% - 90px);
}
div.dataTables_wrapper div.dataTables_paginate {
  text-align: right;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 24px;
}
div.dataTables_wrapper div.dataTables_paginate a.paginate_button {
  cursor: pointer;
  height: 40px;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_paginate span a.paginate_button {
  line-height: 40px;
  padding: 0 8px;
  color: #999 !important;
}
div.dataTables_wrapper div.dataTables_paginate span a.paginate_button.current {
  color: #000 !important;
}
div.dataTables_wrapper div.mdc-grid.dt-table {
  padding-top: 0;
  padding-bottom: 0;
}
div.dataTables_wrapper div.mdc-grid.dt-table > div.mdc-cell {
  margin-top: 0;
  margin-bottom: 0;
}
div.dataTables_wrapper table.mdc-data-table__table {
  table-layout: auto;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_asc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_desc {
  padding-right: 30px;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th:active {
  outline: none;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_asc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_desc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting {
  cursor: pointer;
  position: relative;
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:after,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:before,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:after {
  position: absolute;
  display: block;
  opacity: 0.3;
  right: 1em;
  line-height: 9px;
  font-size: 0.9em;
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:before,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:before {
  bottom: 50%;
  content: "\25B4";
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:after,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:after {
  top: 50%;
  content: "\25BE";
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after {
  opacity: 0.8;
}
div.dataTables_length {
  display: none;
}
div.dataTables_wrapper div.mdc-layout-grid {
  margin: 0px;
  padding: 5px;
}
table.dataTable thead {
  border-bottom: 1px solid #ddd;
}
table.dataTable thead th,
table.dataTable tbody td {
  display: table-cell !important;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > th.sorting_disabled {
  padding-right: 50px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #bbb;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3em;
  text-align: left;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  top: 50%;
  display: block;
  opacity: 0.3;
  font-size: 0.9em;
  margin-top: -7px;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  font-family: "Material Icons";
  right: 3.2em;
  content: "arrow_upward";
  -webkit-font-feature-settings: "liga";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: "Material Icons";
  right: 2.5em;
  content: "arrow_downward";
  -webkit-font-feature-settings: "liga";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
table.dataTable tbody tr {
  border-bottom: 1px solid #ddd;
  transition: 0.2s all;
}
table.dataTable tbody tr:nth-child(2n-1) {
  background-color: #f1f1f1;
}
table.dataTable tbody tr:hover {
  background-color: #e9e9e9;
}
table.dataTable tbody tr td {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.6em;
  padding-right: 20px;
  word-break: break-all;
}
table.dataTable tbody tr td.datatable-data-width-100 {
  max-width: 120px;
  word-break: break-word;
}
table.dataTable tbody tr td a {
  text-decoration: none;
  cursor: pointer;
  padding: 8px 0;
}
table.dataTable tbody tr td .main-datatable-info {
  font-weight: bold;
  cursor: pointer;
}
table.dataTable tbody tr td .group-datatable-info {
  font-weight: bold;
}
table.dataTable thead tr th.editable-field,
table.dataTable tbody tr td.editable-field {
  max-width: 250px;
  min-width: 200px;
  width: 240px !important;
  padding-right: 0;
}
table.dataTable thead tr th.tag,
table.dataTable tbody tr td.tag {
  width: 240px !important;
}
table.dataTable tbody tr td.editable-field .MuiGrid-grid-xs-9 {
  max-width: 170px;
}
table.dataTable thead > tr > th:last-child.sorting:before,
table.dataTable thead > tr > th:last-child.sorting:before,
table.dataTable thead > tr > th:last-child.sorting_asc:before,
table.dataTable thead > tr > th:last-child.sorting_desc:before,
table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
  right: 4.1em !important;
}
table.dataTable thead > tr > th:last-child.sorting:after,
table.dataTable thead > tr > th:last-child.sorting:after,
table.dataTable thead > tr > th:last-child.sorting_asc:after,
table.dataTable thead > tr > th:last-child.sorting_desc:after,
table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
  right: 3.4em !important;
}
.dataTables_scrollHead {
  padding-right: 30px;
  color: #aaa;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 3.6em;
  text-align: left;
}
.MuiChip-colorPrimary {
  color: #05b0ca !important;
  background-color: #08b9d413 !important;
}
.MuiChip-colorSecondary {
  color: #777 !important;
  background-color: rgba(177, 177, 177, 0.1) !important;
}
.paginate_button.previous,
.paginate_button.next {
  position: relative;
  width: 30px;
  overflow: hidden;
  text-indent: 9999px;
}
.paginate_button.previous::before {
  position: absolute;
  display: block;
  width: 30px;
  height: 40px;
  font-size: 2em;
  color: #ccc !important;
  text-indent: -9999px;
  font-family: "Material Icons";
  content: "chevron_left";
  -webkit-font-feature-settings: "liga";
}
.paginate_button.next::before {
  position: absolute;
  display: block;
  width: 30px;
  height: 40px;
  font-size: 2em;
  color: #ccc !important;
  text-indent: -9999px;
  font-family: "Material Icons";
  content: "chevron_right";
  -webkit-font-feature-settings: "liga";
}
.MuiInputBase-input {
  font-size: 14px !important;
}
.resend-btn-td,
.reset-btn-td {
  min-width: 140px;
}
.resend-btn-td span,
.reset-btn-td span {
  margin-bottom: 0 !important;
  cursor: pointer;
  padding: 5px 15px !important;
  font-size: 12px;
  color: #05b0ca !important;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #05b0ca;
  width: auto !important;
  transition: 0.3s;
}
.resend-btn-td span:hover,
.reset-btn-td span:hover {
  background-color: #05b0ca19;
}
div.dataTables_wrapper div.dataTables_paginate > span {
  display: flex;
  align-items: center;
}
.task_name {
  font-weight: bold;
  min-width: 110px;
  max-width: 180px;
}
.task_name_task {
  font-weight: bold;
  min-width: 100px;
}
.serial_number_task{
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;
}
.date_task{
  white-space: nowrap;
  overflow: hidden;
}
#device-eligibility-table {
  margin-bottom: 8px;
}
table#device-eligibility-table tbody tr td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#device-eligibility-table .MuiSvgIcon-colorPrimary {
  color: #1dbbd4 !important;
}
#device-eligibility-table .MuiSvgIcon-colorSecondary {
  color: #ddd !important;
}
.status-connected {
  color: #1dbbd4 !important;
}
.status-disconnected {
  color: #ff9e40 !important;
}
.status-detecting {
  color: #aaa !important;
}
.status-update_fail {
  color: #ff9e40 !important;
}
.status-update_successful {
  color: #1dbbd4 !important;
}
.peripheral-type {
  font-weight: 500;
}
#device-eligibility-table_wrapper {
  padding-bottom: 0;
}
.dt-checkboxes-select-all {
  padding-left: 44px !important;
  cursor: auto !important;
  width: 30px !important; /* remove this padding-left if selected-all input is shown*/
  padding-left: 0px !important; /* remove this padding-left if selected-all input is shown*/
}
.dt-checkboxes-select-all input[type="checkbox"] {
  appearance: none !important;
  display: none !important;
  font-size: 24px;
  color: #aaa;
}
.dt-checkboxes-select-all::before,
.dt-checkboxes-select-all::after {
  display: none !important;
}
.dt-checkboxes-select-all input[type="checkbox"]::before {
  content: "check_box_outline_blank";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
}
.dt-checkboxes-select-all input[type="checkbox"]:checked::before {
  content: "check_box";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
}

.dataTables_scrollHead table.dt-checkboxes-select th.select-checkbox {
  width: 34px !important;
  padding-left: 24px !important;
  padding-right: 28px;
}

.dataTables_scrollBody table.dt-checkboxes-select td.select-checkbox {
  width: 50px !important;
  padding-left: 24px !important;
}

.dataTables_scrollHead table.dt-checkboxes-select th.select-checkbox:before,
.dataTables_scrollHead table.dt-checkboxes-select th.select-checkbox:after {
  display: none;
}

#device-eligibility-table_wrapper th:nth-child(2),
#device-eligibility-table_wrapper td:nth-child(2) {
  width: 50% !important;
}
.owner_name {
  display: block;
}
table.dataTable thead > tr > th.serial_number,
table.dataTable tbody tr td.serial_number {
  width: 15%;
  min-width: 170px !important;
  max-width: 350px !important;
}
table.dataTable thead > tr > th.dt-checkboxes-cell,
table.dataTable tbody tr td.dt-checkboxes-cell {
  width: 10%;
  min-width: 100px !important;
  max-width: 100px !important;
}
table.dataTable thead > tr > th.group_serial_number,
table.dataTable tbody tr td.group_serial_number {
  width: 18%;
  min-width: 200px !important;
  max-width: 350px !important;
}
table.dataTable thead > tr > th.group_serial_number_add,
table.dataTable tbody tr td.group_group_serial_number_add {
  width: 25% !important;
}
table.dataTable thead > tr > th.group_os_type_add,
table.dataTable tbody tr td.group_os_type_add {
  width: 15% !important;
  min-width: 150px !important;
  max-width: 150px !important;
}
table.dataTable thead > tr > th.group_ora_version_add,
table.dataTable tbody tr td.group_ora_version_add {
  width: 30% !important;
  min-width: 250px !important;
  max-width: 250px !important;
}
table.dataTable thead > tr > th.group_os,
table.dataTable tbody tr td.group_os {
  width: 10%;
  min-width: 150px !important;
  max-width: 250px !important;
}
table.dataTable thead > tr > th.group_ora_version,
table.dataTable tbody tr td.group_ora_version {
  width: 15%;
  min-width: 200px !important;
  max-width: 350px !important;
}
table.dataTable thead > tr > th.nubis_version,
table.dataTable tbody tr td.nubis_version {
  width: 160px !important;
}
table.dataTable thead > tr > th.os_type,
table.dataTable tbody tr td.os_type {
  width: 40px !important;
  min-width: 40px !important;
}
table.dataTable thead > tr > th.ora_version,
table.dataTable tbody tr td.ora_version {
  width: 13%;
  min-width: 100px !important;
}
.os_area {
  display: flex;
  align-items: center;
  margin-right: 0 !important;
}
table.dataTable thead > tr > th.power,
table.dataTable tbody tr td.power {
  width: 13%;
  max-width: 140px !important;
  min-width: 80px !important;
}
table.dataTable thead > tr > th.power,
table.dataTable tbody tr td.security {
  width: 13%;
  max-width: 140px !important;
  min-width: 80px !important;
}
table.dataTable thead > tr > th.owners,
table.dataTable tbody tr td.owners {
  width: 15% !important;
  min-width: 110px !important;
  max-width: 200px !important;
}
table.dataTable thead > tr > th.status,
table.dataTable tbody tr td.status {
  width: 10%;
  min-width: 150px !important;
  padding-left: 35px;
}
table.dataTable thead > tr > th.status {
  padding-left: 40px;
}
table.dataTable thead > tr > th.task_name,
table.dataTable tbody tr td.task_name {
  width: 350px !important;
}
table.dataTable thead > tr > th.date,
table.dataTable tbody tr td.date {
  width: 200px !important;
}
table.dataTable thead > tr > th.groups,
table.dataTable tbody tr td.groups {
  width: 20%;
  max-width: 480px !important;
  min-width: 200px !important;
}
table.device-command-datatable thead tr th:first-child,
table.device-command-datatable thead tr th:first-child.sorting_asc,
table.device-command-datatable tbody tr td:first-child {
  width: 40px !important;
  padding-right: 20px !important;
}
table.device-command-datatable thead tr th:first-child::before,
table.device-command-datatable thead tr th:first-child::after {
  display: none;
}
table.device-command-datatable thead tr th:nth-child(2),
table.device-command-datatable tbody tr td:nth-child(2) {
  min-width: 200px !important;
}
.MuiTableCell-alignRight {
  max-width: 530px;
}
div#device-eligibility-table_wrapper {
  position: relative;
  padding-bottom: 50px;
}
div#device-eligibility-table_wrapper div.dataTables_info {
  bottom: 10px;
}

div#device-eligibility-table_wrapper div.dataTables_info .select-info {
  display: none;
}
table#alarm-table thead tr th:first-child,
table#alarm-table tbody tr td:first-child {
  width: 300px;
}

thead tr th.sorting_disabled:before,
thead tr th.sorting_disabled:after {
  content: "" !important;
}

th.file-name,
td.file-name {
  width: 50% !important;
}

td.file-name {
  font-weight: bolder;
}

td.file-name .MuiFormControl-root {
  width: 95% !important;
}

td.file-name .folder-link input.Mui-disabled {
  cursor: pointer;
}

td.file-name .MuiBox-root > svg,
td.file-name .folder-link > svg {
  margin-right: 18px !important;
  cursor: initial;
}

td.td-center {
  text-align: center;
  padding-right: 60px !important;
}

td.td-center a {
  text-decoration: underline !important;
}

th.reset-status-td {
  width: 160px;
}

#alarm-policy-deploy-table thead tr th:first-child,
#alarm-policy-deploy-table tbody tr td:first-child {
  padding-left: 32px !important;
}

.datatable-with-title-searchbar-wrap {
  position: relative;
}
.datatable-with-title-searchbar-wrap h5 {
  margin-left: 24px;
  top: 18px;
}

.datatable-with-title-searchbar-wrap table {
  margin-top: 20px !important;
}

.datatable-with-title-searchbar-wrap .dataTables_wrapper {
  padding-bottom: 20px !important;
}

.datatable-with-title-searchbar-wrap .dataTables_wrapper div.dataTables_info {
  bottom: -10px !important;
}

.datatable-with-title-searchbar-wrap
  .dataTables_wrapper
  div.dataTables_paginate {
  padding-top: 10px;
}

div.task-device-table-full-width {
  overflow-x: hidden;
  margin-bottom: -30px;
}

div.task-device-table-full-width > div {
  margin: 0 !important;
  top: 0px;
}

div.task-device-table-full-width div.dataTables_wrapper div.dataTables_filter {
  top: 4px;
}

div.task-device-table-full-width > div table.dataTable tbody tr td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div.task-device-table-full-width .mdl-data-table {
  width: 100% !important;
}

div.task-device-table-full-width .dataTables_scrollHead {
  padding-right: 0;
}

div.task-device-table-full-width > div table.dataTable thead tr th,
div.task-device-table-full-width > div table.dataTable tbody tr td {
  min-width: initial !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  thead
  tr
  th.select-checkbox {
  min-width: initial !important;
  width: 102px !important;
  padding-right: 20px !important;
  padding-left: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  thead
  tr
  th.serial-number,
div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.serial-number {
  width: 290px !important;
  padding-right: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width > div table.dataTable thead tr th.os-type,
div.task-device-table-full-width > div table.dataTable tbody tr td.os-type {
  width: 200px !important;
  padding-right: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width > div table.dataTable thead tr th.egilibility,
div.task-device-table-full-width > div table.dataTable tbody tr td.egilibility {
  width: 200px !important;
  padding-right: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.egilibility
  .MuiSvgIcon-root {
  margin-top: 4px;
}

div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.egilibility
  .MuiSvgIcon-colorPrimary {
  color: #1dbbd4 !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.egilibility
  .MuiSvgIcon-colorSecondary {
  color: #ddd !important;
}

.select-info {
  display: none;
}
.tableIcon {
  width: 33px;
  height: 23px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.power .tableIcon svg {
  display: flex;
  align-items: center;
  width: 19px !important;
  height: 19px !important;
}
.security .tableIcon svg {
  display: flex;
  align-items: center;
  width: 17px !important;
  height: 17px !important;
}

table.dataTable tbody tr td.checkbox .disabledCheckboxOuter {
  padding: 9px 10px;
}
table.dataTable tbody tr td.checkbox .disabledCheckbox {
  width: 15px;
  height: 15px;
  border: 2px solid #ddd;
  margin: 0;
  background-color: #fbfbfb;
  border-radius: 3px;
  cursor: not-allowed;
}
table.dataTable tbody tr td.appVersionManagement .appVersionManagementBtn {
  width: 200px;
  color: #1dbbd4;
  text-align: center;
  border: 1px solid #1dbbd4;
  padding: 4px 10px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
}

table.dataTable tbody tr td.stopApplication .stopApplicationOuter .stopBtn {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #f98746;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
table.dataTable tbody tr td.stopApplication .stopApplicationOuter .stopBtn .square {
  width: 8px;
  height: 8px;
  background-color: #fff;
}
table.dataTable tbody tr td.stopApplication .stopApplicationOuter .stopBtn.disabled {
  background-color: #ccc;
}

@media screen and (max-width: 1680px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 50px;
  }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 1.5em !important;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 3.1em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 2.3em !important;
  }
  table#alarm-table thead tr th:nth-child(2),
  table#alarm-table tbody tr td:nth-child(2) {
    max-width: 600px;
  }
}

@media screen and (max-width: 1440px) {
  table.dataTable thead > tr > th.sorting:before,
  table.dataTable thead > tr > th.sorting_asc:before,
  table.dataTable thead > tr > th.sorting_desc:before,
  table.dataTable thead > tr > th.sorting_asc_disabled:before,
  table.dataTable thead > tr > th.sorting_desc_disabled:before {
    right: 1.2em !important;
  }
  table.dataTable thead > tr > th.sorting:after,
  table.dataTable thead > tr > th.sorting_asc:after,
  table.dataTable thead > tr > th.sorting_desc:after,
  table.dataTable thead > tr > th.sorting_asc_disabled:after,
  table.dataTable thead > tr > th.sorting_desc_disabled:after {
    right: 0.5em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 2.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 2em !important;
  }
  table#alarm-table thead tr th:nth-child(2),
  table#alarm-table tbody tr td:nth-child(2) {
    max-width: 370px;
  }
}
@media screen and (max-width: 1279px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 40px;
  }
  table.dataTable tbody tr td.editable-field {
    max-width: 200px;
    min-width: 150px;
  }
  table.dataTable thead > tr > th.sorting:before,
  table.dataTable thead > tr > th.sorting_asc:before,
  table.dataTable thead > tr > th.sorting_desc:before,
  table.dataTable thead > tr > th.sorting_asc_disabled:before,
  table.dataTable thead > tr > th.sorting_desc_disabled:before {
    right: 2em !important;
  }
  table.dataTable thead > tr > th.sorting:after,
  table.dataTable thead > tr > th.sorting_asc:after,
  table.dataTable thead > tr > th.sorting_desc:after,
  table.dataTable thead > tr > th.sorting_asc_disabled:after,
  table.dataTable thead > tr > th.sorting_desc_disabled:after {
    right: 1.1em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 2.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 1.8em !important;
  }
  table#alarm-table thead tr th:nth-child(2),
  table#alarm-table tbody tr td:nth-child(2) {
    max-width: 300px;
  }
}
@media screen and (max-width: 1024px) {
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting:before {
    right: 3.1em !important;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting:after {
    right: 2.4em !important;
  }
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  div.dataTables_wrapper div.dataTables_filter input {
    width: 80%;
  }
}
@media screen and (max-width: 670px) {
  div.dataTables_wrapper div.dataTables_filter {
    min-width: 100px;
    max-width: 150px;
    width: 40%;
  }
}
@media screen and (max-width: 599px) {
  div.dataTables_wrapper div.dataTables_filter {
    right: 50%;
    margin-right: -106.5px;
    width: 100%;
    max-width: 230px;
  }
  div.dataTables_wrapper div.dataTables_filter input {
    width: 100%;
  }
}
