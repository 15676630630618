.bold {
  font-weight: 600;
}
.w-100{
  width: 100%;
}
.flex {
  display: flex;
}
.flex_justify_center {
  display: flex;
  justify-content: center;
}
.flex_align_center {
  display: flex;
  align-items: center;
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.mr-0 {
  margin-right: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-1 {
  margin-right: 5px;
}
.ml-1 {
  margin-left: 5px;
}
.mt-1 {
  margin-top: 5px;
}
.mb-1 {
  margin-bottom: 5px;
}
.mr-2 {
  margin-right: 10px;
}
.ml-2 {
  margin-left: 10px;
}
.mt-2 {
  margin-top: 10px;
}
.mb-2 {
  margin-bottom: 10px;
}
.mr-3 {
  margin-right: 15px;
}
.ml-3 {
  margin-left: 15px;
}
.mt-3 {
  margin-top: 15px;
}
.mb-3 {
  margin-bottom: 15px;
}
.mr-4 {
  margin-right: 20px;
}
.ml-4 {
  margin-left: 20px;
}
.mt-4 {
  margin-top: 20px;
}
.mb-4 {
  margin-bottom: 20px;
}
.mr-5 {
  margin-right: 25px;
}
.ml-5 {
  margin-left: 25px;
}
.mt-5 {
  margin-top: 25px;
}
.mb-5 {
  margin-bottom: 25px;
}
.mr-6 {
  margin-right: 30px;
}
.ml-6 {
  margin-left: 30px;
}
.mt-6 {
  margin-top: 30px;
}
.mb-6 {
  margin-bottom: 30px;
}
