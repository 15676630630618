html {
  font-size: 12px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiase;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.8em;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #000 !important;
}

a:hover {
  color: #000 !important;
}

.MuiBreadcrumbs-ol {
  margin-bottom: 30px !important;
}

.MuiBreadcrumbs-li a {
  color: #888 !important;
}

.MuiBreadcrumbs-li p {
  color: #333 !important;
}

.MuiButtonGroup-groupedTextSecondary:not(:last-child) {
  border-color: #9999992e !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.05),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiPickersToolbarText-toolbarBtnSelected {
  color: #fff !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: #efefef !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: #efefef !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #2cbdd8 !important;
}

.MuiTypography-colorSecondary {
  color: #999 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1dbbd4 !important;
}

.MuiSvgIcon-colorSecondary,
.MuiFormControlLabel-root.Mui-disabled .MuiCheckbox-colorSecondary.Mui-checked {
  color: #ccc !important;
}

.MuiGrid-item {
  overflow: hidden !important;
}

.MuiListItem-root.Mui-disabled {
  opacity: 0.3 !important;
}

.MuiSvgIcon-colorPrimary,
.MuiTypography-colorPrimary {
  color: #1dbbd4 !important;
}

.MuiTypography-colorError {
  color: #ff9e40 !important;
}

.MuiDialog-paperWidthSm {
  max-width: 340px !important;
}

.MuiDialog-paperWidthSm .MuiDialogTitle-root,
.MuiDialog-paperWidthSm .MuiDialogContent-root {
  padding-left: 14px;
  padding-right: 14px;
}

.MuiDialog-paperWidthMd {
  width: 600px;
}

.MuiDialog-paperWidthLg {
  width: 1000px;
}

.smaller-loading-indicator {
  height: 100%;
}

.smaller-loading-indicator .loading-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.5);
}

svg.MuiSvgIcon-root.shareIcon {
  transform: rotateY(180deg);
}

#lang-select .MuiPopover-paper {
  top: 58px !important;
}

td.file-name .MuiBox-root > svg {
  cursor: initial !important;
}

td.file-name .MuiBox-root > svg.file-icon {
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #eee;
  width: 18px;
  height: 18px;
  margin-left: 1px;
  padding: 0 1px;
}

.ml_8 {
  margin-left: 8px !important;
}

.mt_16 {
  margin-top: 16px !important;
}

.mb_16 {
  margin-bottom: 16px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.pointer {
  cursor: pointer !important;
}

.no-border .MuiInput-underline:before,
.no-border .MuiInput-underline:after {
  border: 0 !important;
}

/* dropdown lazyloading */

.dropdown-lazyloading-wrap {
  position: absolute !important;
  width: 300px !important;
}

.ui.fluid.dropdown {
  margin-top: 7px !important;
  display: flex !important;
  align-items: center;
}

.ui.selection.dropdown {
  border-radius: 20px !important;
  height: 40px !important;
  border-color: #1dbbd4 !important;
  word-break: break-all !important;
}

.ui.selection.dropdown.disabled {
  border-color: #22242659 !important;
}

.ui.search.dropdown .menu {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.needDefault .ui.search.selection.dropdown > input.search {
  height: 40px !important;
  padding: 10px 24px !important;
  cursor: pointer !important;
}

.ui.search.dropdown > .text {
  left: 12px !important;
  padding-right: 20px;
  cursor: pointer !important;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  top: 12px !important;
}

.ui.selection.dropdown.fluid .menu > .item {
  padding: 10px 24px !important;
  width: 100%;
  height: 100%;
}
.ui.selection.dropdown.fluid .menu > .item .text {
  width: 100%;
}
.ui.selection.dropdown.fluid .menu > .item svg {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.needDefault .ui.default.dropdown:not(.button) > .text,
.needDefault .ui.dropdown:not(.button) > .default.text {
  color: #7d7d7d !important;
  text-transform: uppercase;
}
.ui.dropdown.multiple .default {
  top: 5px !important;
}
.chip-scroll-area {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.paginate_button.previous::before,
.paginate_button.next::before {
  top: 2px !important;
}
.no-input .dataTables_filter {
  display: none;
}
.custom-search {
  width: 200px;
  right: 10px;
  position: absolute;
  top: -7px;
}
.MuiAutocomplete-listbox {
  padding: 0 !important;
  overflow-x: hidden !important;
}
.MuiAutocomplete-paper {
  padding: 0 !important;
}
#single-popup .MuiAutocomplete-option {
  padding: 15px 20px;
}

.selectAll {
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 2px -2px 2px #f0f0f0, -2px 2px 2px #f0f0f0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.selectAll .MuiCheckbox-root {
  padding: 5px;
}
.selectAll .allText {
  margin-left: 7px;
}
.selectAll .MuiIconButton-label svg {
  width: 15px;
  height: 15px;
}

#multipleSelect .MuiAutocomplete-option {
  padding: 15px 5px;
}
#multipleSelect .MuiCheckbox-root {
  padding: 0 5px 0 0;
}
#multipleSelect .MuiAutocomplete-paper {
  margin: 0 !important;
  border-radius: 0 0 10px 10px !important;
}
button#searchButton {
  margin-right: 0 !important;
  padding: 0 !important;
  width: 44px;
  min-width: 44px !important;
  height: 44px;
  border-radius: 50%;
  margin-bottom: 0 !important;
}
/* flex */

.flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-center {
  align-items: center !important;
}

/* button wrap */

.submit-back-btn-wrap {
  margin-top: 32px;
  display: flex !important;
}

.submit-back-btn-wrap button {
  width: 150px !important;
  margin-right: 10px !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.submit-back-btn-wrap button.MuiButton-containedSecondary {
  margin-right: 0 !important;
}

.MuiDialog-paper .submit-back-btn-wrap button {
  box-shadow: none !important;
}

.MuiDialogActions-root .MuiButton-root.MuiButton-containedPrimary {
  margin-right: 10px !important;
}

/* MuiList in Collapse */

.MuiCollapse-entered {
  width: 100%;
}

.MuiCollapse-wrapper .MuiListItem-root {
  border: none;
  padding: 1px 1px 1px 32px;
  align-items: initial;
}

.MuiCollapse-wrapper .MuiListItem-root a,
.MuiCollapse-wrapper .MuiListItem-root .MuiTypography-root {
  color: #999;
}

/* Password Related */

.password-input-wrap {
  position: relative;
}

.password-input-wrap .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.password-input-wrap .MuiOutlinedInput-root {
  border-radius: 8px;
}

.password-input-wrap
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.password-input-wrap .MuiIconButton-root {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dialog_submit_btn_wrap > div,
.dialog_submit_btn_wrap > button {
  margin-bottom: 16px !important;
}

/* Create Folder Related */

.create-folder-wrap .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.create-folder-wrap .MuiButton-containedPrimary {
  margin-bottom: 0 !important;
  width: 120px !important;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cusorPoint {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed !important;
}
/* Fullcalendar */
.fc-daygrid-day,
.fc-timegrid-slot,
.fc-event-main {
  cursor: pointer;
}

.fc-today-button {
  background-color: #b5b5b5 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
  width: 70px;
  height: 27px;
}
.fc-prev-button,
.fc-next-button {
  background-color: #f3f3f3 !important;
  border: none !important;
  color: #868383 !important;
  width: 45px;
  height: 27px;
}
.fc-header-toolbar {
  padding: 0 30px;
  margin: 30px 0 !important;
}
.fc-button-primary:disabled {
  background-color: #f3f3f3 !important;
  color: #868383 !important;
  cursor: not-allowed;
}
.fc-day-today {
  background: #fff !important;
}
.fc-daygrid-day-top {
  justify-content: center;
}
.fc-daygrid-day-number {
  color: #333 !important;
  margin-top: 5px;
}
.fc-day-today .fc-daygrid-day-number {
  background-color: #1dbbd4;
  color: #fff !important;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc-popover-header {
  background-color: #fff !important;
  font-size: 16px;
  padding: 10px 10px 0 10px !important;
}
.fc-daygrid-event {
  background-color: #eee !important;
  border: #fff !important;
  padding: 1.5px 5px !important;
  margin: 1.5px 3px !important;
  border-radius: 5px !important;
}
.fc-event-main,
.fc-toolbar-title,
.fc-more-link {
  color: #333 !important;
}
.fc-col-header-cell-cushion {
  color: #333 !important;
  padding: 5px 0 !important;
}
.event-outer {
  display: flex;
  align-items: center;
}
.fc-cancel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px !important;
  height: 15px !important;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
  opacity: 0;
}
.fc-cancel img {
  width: 15px !important;
  height: 15px !important;
}
.fc-daygrid-event:hover .fc-cancel {
  opacity: 1;
}
.fc-daygrid-event {
  position: relative;
  cursor: pointer;
}
.fc-groupSelect-button {
  border-color: #fff !important;
  background-color: #fff !important;
  padding: 0 !important;
  border-radius: 50px !important;
}
.fc-popover {
  border-radius: 20px !important;
  box-shadow: 0 10px 20px #b5b5b5 !important;
  z-index: 1000 !important;
}
.fc-delete {
  width: 30px !important;
  height: 30px !important;
  margin-left: 20px !important;
  cursor: pointer;
}
.fc-disiableTool-button {
  background: none !important;
  border: 0 !important;
  padding: 0 !important;
}
.fc-disiableTool-button .fc-button-group {
  margin-right: 10px;
}
.fc-refreshTool-button {
  background: none !important;
  border: 0 !important;
  color: #999 !important;
  padding: 0 !important;
  height: 20px;
}
.fc-refreshTool-button svg {
  margin-top: 0 !important;
}
.fc-disabled .fc-button {
  cursor: not-allowed !important;
}
.fc-refreshTool-button-disabled {
  background: none !important;
  display: inline-block !important;
  border: 0 !important;
  color: #999 !important;
  padding: 0 !important;
  margin: 0 !important;
  user-select: none !important;
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 1em !important;
  line-height: 1.5 !important;
  margin-left: 0.75em !important;
  height: 20px !important;
}
.deleteBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  width: 20px;
}
.deleteBtnDisabled {
  display: flex;
  align-items: center;
  cursor: not-allowed;
  opacity: 0.3;
  margin-left: 10px;
  width: 20px;
}
.table_detail_btn {
  width: 70px;
  height: 25px;
  border: 1px solid #1dbbd4;
  color: #1dbbd4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.15s;
}
.table_detail_btn:hover {
  background-color: #1dbbd4;
  color: #fff;
}
.Mui-disabled + .MuiSwitch-track {
  opacity: 0.12 !important;
}

.site-tree-search-value {
  color: #ff9e40;
}
.upsideScrollbar {
  cursor: grab;
  max-height: 450px;
  overflow-x: hidden;
}
.ant-tree-switcher {
  cursor: grab !important;
}
.ant-tree-switcher_close {
  cursor: pointer !important;
}
.ant-tree-switcher_open {
  cursor: pointer !important;
}
.ant-tree-list {
  margin-top: 10px;
  padding-bottom: 20px;
}
.upsideScrollbar::-webkit-scrollbar {
  width: 5px !important;
}
.site-tree-search-value {
  white-space: nowrap;
  width: 100%;
}
.tree-outer {
  white-space: nowrap;
  font-size: 12px;
}
.tree-num {
  color: #999;
  font-size: 12px;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #04b7d2 !important;
  color: white;
  font-weight: bold;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected .tree-num {
  background-color: #04b7d2 !important;
  color: white;
  font-weight: bold;
}
.ant-tree-treenode-selected .tree-outer {
  color: inherit;
}
.ant-tree-treenode-selected .tree-outer .site-tree-search-value {
  color: inherit;
}
.ant-tree-treenode-default .tree-outer {
  color: inherit;
}
.ui.multiple.search.dropdown > input.search {
  line-height: 3.214286em !important;
}
.ui.multiple.search.dropdown {
  padding-left: 20px !important;
}
.ui.multiple.search.dropdown .divider {
  padding-left: 0 !important;
}
.ui.multiple.dropdown > .label {
  vertical-align: middle !important;
}
.ui.search.dropdown > .text {
  display: flex;
  align-items: center;
}
.ui.dropdown .menu > .item {
  display: flex !important;
  align-items: center;
}

.select-breadcrumbs {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  cursor: grab;
  align-items: center;
  padding-bottom: 5px;
}

.select-breadcrumb-inner {
  display: flex;
  align-items: center;
}

.select-breadcrumb-root {
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: #888;
  cursor: pointer;
}

.select-breadcrumb {
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: #888;
  cursor: pointer;
}

.select-breadcrumb-active {
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #333;
}
.MuiPickersModal-dialog {
  overflow-x: hidden;
}
.successColor {
  color: #1dbbd4;
}
.errorColor {
  color: #ff9e40;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.MuiAutocomplete-paper .MuiAutocomplete-listbox {
    max-height: 20vh
}
