.root{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}
.loading-wrapper{
  height: 100px;
  margin: 0 auto;
}
.table-loading-wrapper{
  transform: scale(0.5);
}
.table-loading-wrapper .loadingio-spinner-ellipsis-0e9htm6lpb7{
  background-color: transparent;
}
.siteLogoLink_img{
  width: 10%;
  min-width: 15px;
  z-index: -1;
  position: absolute;
  bottom: 4%;
  display:  flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #aaa;
}
@keyframes ldio-tk8dyrglbc {
  0% { transform: translate(6.6000000000000005px,44px) scale(0); }
 25% { transform: translate(6.6000000000000005px,44px) scale(0); }
 50% { transform: translate(6.6000000000000005px,44px) scale(1); }
 75% { transform: translate(44px,44px) scale(1); }
100% { transform: translate(81.4px,44px) scale(1); }
}
@keyframes ldio-tk8dyrglbc-r {
  0% { transform: translate(81.4px,44px) scale(1); }
100% { transform: translate(81.4px,44px) scale(0); }
}
@keyframes ldio-tk8dyrglbc-c {
  0% { background: #04b7d2 }
 25% { background: #04b7d2 }
 50% { background: #04b7d2 }
 75% { background: #04b7d2 }
100% { background: #04b7d2 }
}
.ldio-tk8dyrglbc div {
 position: absolute;
 width: 22px;
 height: 22px;
 border-radius: 50%;
 transform: translate(44px,44px) scale(1);
 background: #04b7d2;
 animation: ldio-tk8dyrglbc 2s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-tk8dyrglbc div:nth-child(1) {
 background: #04b7d2;
 transform: translate(81.4px,44px) scale(1);
 animation: ldio-tk8dyrglbc-r 0.5s infinite cubic-bezier(0,0.5,0.5,1), ldio-tk8dyrglbc-c 2s infinite step-start;
}.ldio-tk8dyrglbc div:nth-child(2) {
 animation-delay: -0.5s;
 background: #04b7d2;
}.ldio-tk8dyrglbc div:nth-child(3) {
 animation-delay: -1s;
 background: #04b7d2;
}.ldio-tk8dyrglbc div:nth-child(4) {
 animation-delay: -1.5s;
 background: #04b7d2;
}.ldio-tk8dyrglbc div:nth-child(5) {
 animation-delay: -2s;
 background: #04b7d2;
}
.loadingio-spinner-ellipsis-0e9htm6lpb7 {
 width: 110px;
 height: 110px;
 display: inline-block;
 overflow: hidden;
}
.ldio-tk8dyrglbc {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(1);
 backface-visibility: hidden;
 transform-origin: 0 0; /* see note above */
}
.ldio-tk8dyrglbc div { box-sizing: content-box; }
/* generated by https://loading.io/ */

@media screen and (min-width: 1920px) {
  .loading-wrapper{
    height: 110px;
    margin: 0 auto;
  }
}