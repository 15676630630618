@import url(https://cdnjs.cloudflare.com/ajax/libs/material-components-web/4.0.0/material-components-web.min.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.bold {
  font-weight: 600;
}
.w-100{
  width: 100%;
}
.flex {
  display: flex;
}
.flex_justify_center {
  display: flex;
  justify-content: center;
}
.flex_align_center {
  display: flex;
  align-items: center;
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.mr-0 {
  margin-right: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-1 {
  margin-right: 5px;
}
.ml-1 {
  margin-left: 5px;
}
.mt-1 {
  margin-top: 5px;
}
.mb-1 {
  margin-bottom: 5px;
}
.mr-2 {
  margin-right: 10px;
}
.ml-2 {
  margin-left: 10px;
}
.mt-2 {
  margin-top: 10px;
}
.mb-2 {
  margin-bottom: 10px;
}
.mr-3 {
  margin-right: 15px;
}
.ml-3 {
  margin-left: 15px;
}
.mt-3 {
  margin-top: 15px;
}
.mb-3 {
  margin-bottom: 15px;
}
.mr-4 {
  margin-right: 20px;
}
.ml-4 {
  margin-left: 20px;
}
.mt-4 {
  margin-top: 20px;
}
.mb-4 {
  margin-bottom: 20px;
}
.mr-5 {
  margin-right: 25px;
}
.ml-5 {
  margin-left: 25px;
}
.mt-5 {
  margin-top: 25px;
}
.mb-5 {
  margin-bottom: 25px;
}
.mr-6 {
  margin-right: 30px;
}
.ml-6 {
  margin-left: 30px;
}
.mt-6 {
  margin-top: 30px;
}
.mb-6 {
  margin-bottom: 30px;
}

html {
  font-size: 12px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiase;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.8em;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #000 !important;
}

a:hover {
  color: #000 !important;
}

.MuiBreadcrumbs-ol {
  margin-bottom: 30px !important;
}

.MuiBreadcrumbs-li a {
  color: #888 !important;
}

.MuiBreadcrumbs-li p {
  color: #333 !important;
}

.MuiButtonGroup-groupedTextSecondary:not(:last-child) {
  border-color: #9999992e !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.05),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiPickersToolbarText-toolbarBtnSelected {
  color: #fff !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: #efefef !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: #efefef !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #2cbdd8 !important;
}

.MuiTypography-colorSecondary {
  color: #999 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1dbbd4 !important;
}

.MuiSvgIcon-colorSecondary,
.MuiFormControlLabel-root.Mui-disabled .MuiCheckbox-colorSecondary.Mui-checked {
  color: #ccc !important;
}

.MuiGrid-item {
  overflow: hidden !important;
}

.MuiListItem-root.Mui-disabled {
  opacity: 0.3 !important;
}

.MuiSvgIcon-colorPrimary,
.MuiTypography-colorPrimary {
  color: #1dbbd4 !important;
}

.MuiTypography-colorError {
  color: #ff9e40 !important;
}

.MuiDialog-paperWidthSm {
  max-width: 340px !important;
}

.MuiDialog-paperWidthSm .MuiDialogTitle-root,
.MuiDialog-paperWidthSm .MuiDialogContent-root {
  padding-left: 14px;
  padding-right: 14px;
}

.MuiDialog-paperWidthMd {
  width: 600px;
}

.MuiDialog-paperWidthLg {
  width: 1000px;
}

.smaller-loading-indicator {
  height: 100%;
}

.smaller-loading-indicator .loading-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

svg.MuiSvgIcon-root.shareIcon {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

#lang-select .MuiPopover-paper {
  top: 58px !important;
}

td.file-name .MuiBox-root > svg {
  cursor: auto !important;
  cursor: initial !important;
}

td.file-name .MuiBox-root > svg.file-icon {
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #eee;
  width: 18px;
  height: 18px;
  margin-left: 1px;
  padding: 0 1px;
}

.ml_8 {
  margin-left: 8px !important;
}

.mt_16 {
  margin-top: 16px !important;
}

.mb_16 {
  margin-bottom: 16px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.pointer {
  cursor: pointer !important;
}

.no-border .MuiInput-underline:before,
.no-border .MuiInput-underline:after {
  border: 0 !important;
}

/* dropdown lazyloading */

.dropdown-lazyloading-wrap {
  position: absolute !important;
  width: 300px !important;
}

.ui.fluid.dropdown {
  margin-top: 7px !important;
  display: flex !important;
  align-items: center;
}

.ui.selection.dropdown {
  border-radius: 20px !important;
  height: 40px !important;
  border-color: #1dbbd4 !important;
  word-break: break-all !important;
}

.ui.selection.dropdown.disabled {
  border-color: #22242659 !important;
}

.ui.search.dropdown .menu {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.needDefault .ui.search.selection.dropdown > input.search {
  height: 40px !important;
  padding: 10px 24px !important;
  cursor: pointer !important;
}

.ui.search.dropdown > .text {
  left: 12px !important;
  padding-right: 20px;
  cursor: pointer !important;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  top: 12px !important;
}

.ui.selection.dropdown.fluid .menu > .item {
  padding: 10px 24px !important;
  width: 100%;
  height: 100%;
}
.ui.selection.dropdown.fluid .menu > .item .text {
  width: 100%;
}
.ui.selection.dropdown.fluid .menu > .item svg {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.needDefault .ui.default.dropdown:not(.button) > .text,
.needDefault .ui.dropdown:not(.button) > .default.text {
  color: #7d7d7d !important;
  text-transform: uppercase;
}
.ui.dropdown.multiple .default {
  top: 5px !important;
}
.chip-scroll-area {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.paginate_button.previous::before,
.paginate_button.next::before {
  top: 2px !important;
}
.no-input .dataTables_filter {
  display: none;
}
.custom-search {
  width: 200px;
  right: 10px;
  position: absolute;
  top: -7px;
}
.MuiAutocomplete-listbox {
  padding: 0 !important;
  overflow-x: hidden !important;
}
.MuiAutocomplete-paper {
  padding: 0 !important;
}
#single-popup .MuiAutocomplete-option {
  padding: 15px 20px;
}

.selectAll {
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 2px -2px 2px #f0f0f0, -2px 2px 2px #f0f0f0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.selectAll .MuiCheckbox-root {
  padding: 5px;
}
.selectAll .allText {
  margin-left: 7px;
}
.selectAll .MuiIconButton-label svg {
  width: 15px;
  height: 15px;
}

#multipleSelect .MuiAutocomplete-option {
  padding: 15px 5px;
}
#multipleSelect .MuiCheckbox-root {
  padding: 0 5px 0 0;
}
#multipleSelect .MuiAutocomplete-paper {
  margin: 0 !important;
  border-radius: 0 0 10px 10px !important;
}
button#searchButton {
  margin-right: 0 !important;
  padding: 0 !important;
  width: 44px;
  min-width: 44px !important;
  height: 44px;
  border-radius: 50%;
  margin-bottom: 0 !important;
}
/* flex */

.flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-center {
  align-items: center !important;
}

/* button wrap */

.submit-back-btn-wrap {
  margin-top: 32px;
  display: flex !important;
}

.submit-back-btn-wrap button {
  width: 150px !important;
  margin-right: 10px !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.submit-back-btn-wrap button.MuiButton-containedSecondary {
  margin-right: 0 !important;
}

.MuiDialog-paper .submit-back-btn-wrap button {
  box-shadow: none !important;
}

.MuiDialogActions-root .MuiButton-root.MuiButton-containedPrimary {
  margin-right: 10px !important;
}

/* MuiList in Collapse */

.MuiCollapse-entered {
  width: 100%;
}

.MuiCollapse-wrapper .MuiListItem-root {
  border: none;
  padding: 1px 1px 1px 32px;
  align-items: initial;
}

.MuiCollapse-wrapper .MuiListItem-root a,
.MuiCollapse-wrapper .MuiListItem-root .MuiTypography-root {
  color: #999;
}

/* Password Related */

.password-input-wrap {
  position: relative;
}

.password-input-wrap .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.password-input-wrap .MuiOutlinedInput-root {
  border-radius: 8px;
}

.password-input-wrap
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.password-input-wrap .MuiIconButton-root {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dialog_submit_btn_wrap > div,
.dialog_submit_btn_wrap > button {
  margin-bottom: 16px !important;
}

/* Create Folder Related */

.create-folder-wrap .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.create-folder-wrap .MuiButton-containedPrimary {
  margin-bottom: 0 !important;
  width: 120px !important;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cusorPoint {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed !important;
}
/* Fullcalendar */
.fc-daygrid-day,
.fc-timegrid-slot,
.fc-event-main {
  cursor: pointer;
}

.fc-today-button {
  background-color: #b5b5b5 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
  width: 70px;
  height: 27px;
}
.fc-prev-button,
.fc-next-button {
  background-color: #f3f3f3 !important;
  border: none !important;
  color: #868383 !important;
  width: 45px;
  height: 27px;
}
.fc-header-toolbar {
  padding: 0 30px;
  margin: 30px 0 !important;
}
.fc-button-primary:disabled {
  background-color: #f3f3f3 !important;
  color: #868383 !important;
  cursor: not-allowed;
}
.fc-day-today {
  background: #fff !important;
}
.fc-daygrid-day-top {
  justify-content: center;
}
.fc-daygrid-day-number {
  color: #333 !important;
  margin-top: 5px;
}
.fc-day-today .fc-daygrid-day-number {
  background-color: #1dbbd4;
  color: #fff !important;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc-popover-header {
  background-color: #fff !important;
  font-size: 16px;
  padding: 10px 10px 0 10px !important;
}
.fc-daygrid-event {
  background-color: #eee !important;
  border: #fff !important;
  padding: 1.5px 5px !important;
  margin: 1.5px 3px !important;
  border-radius: 5px !important;
}
.fc-event-main,
.fc-toolbar-title,
.fc-more-link {
  color: #333 !important;
}
.fc-col-header-cell-cushion {
  color: #333 !important;
  padding: 5px 0 !important;
}
.event-outer {
  display: flex;
  align-items: center;
}
.fc-cancel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px !important;
  height: 15px !important;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
  opacity: 0;
}
.fc-cancel img {
  width: 15px !important;
  height: 15px !important;
}
.fc-daygrid-event:hover .fc-cancel {
  opacity: 1;
}
.fc-daygrid-event {
  position: relative;
  cursor: pointer;
}
.fc-groupSelect-button {
  border-color: #fff !important;
  background-color: #fff !important;
  padding: 0 !important;
  border-radius: 50px !important;
}
.fc-popover {
  border-radius: 20px !important;
  box-shadow: 0 10px 20px #b5b5b5 !important;
  z-index: 1000 !important;
}
.fc-delete {
  width: 30px !important;
  height: 30px !important;
  margin-left: 20px !important;
  cursor: pointer;
}
.fc-disiableTool-button {
  background: none !important;
  border: 0 !important;
  padding: 0 !important;
}
.fc-disiableTool-button .fc-button-group {
  margin-right: 10px;
}
.fc-refreshTool-button {
  background: none !important;
  border: 0 !important;
  color: #999 !important;
  padding: 0 !important;
  height: 20px;
}
.fc-refreshTool-button svg {
  margin-top: 0 !important;
}
.fc-disabled .fc-button {
  cursor: not-allowed !important;
}
.fc-refreshTool-button-disabled {
  background: none !important;
  display: inline-block !important;
  border: 0 !important;
  color: #999 !important;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 1em !important;
  line-height: 1.5 !important;
  margin-left: 0.75em !important;
  height: 20px !important;
}
.deleteBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  width: 20px;
}
.deleteBtnDisabled {
  display: flex;
  align-items: center;
  cursor: not-allowed;
  opacity: 0.3;
  margin-left: 10px;
  width: 20px;
}
.table_detail_btn {
  width: 70px;
  height: 25px;
  border: 1px solid #1dbbd4;
  color: #1dbbd4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.15s;
}
.table_detail_btn:hover {
  background-color: #1dbbd4;
  color: #fff;
}
.Mui-disabled + .MuiSwitch-track {
  opacity: 0.12 !important;
}

.site-tree-search-value {
  color: #ff9e40;
}
.upsideScrollbar {
  cursor: grab;
  max-height: 450px;
  overflow-x: hidden;
}
.ant-tree-switcher {
  cursor: grab !important;
}
.ant-tree-switcher_close {
  cursor: pointer !important;
}
.ant-tree-switcher_open {
  cursor: pointer !important;
}
.ant-tree-list {
  margin-top: 10px;
  padding-bottom: 20px;
}
.upsideScrollbar::-webkit-scrollbar {
  width: 5px !important;
}
.site-tree-search-value {
  white-space: nowrap;
  width: 100%;
}
.tree-outer {
  white-space: nowrap;
  font-size: 12px;
}
.tree-num {
  color: #999;
  font-size: 12px;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #04b7d2 !important;
  color: white;
  font-weight: bold;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected .tree-num {
  background-color: #04b7d2 !important;
  color: white;
  font-weight: bold;
}
.ant-tree-treenode-selected .tree-outer {
  color: inherit;
}
.ant-tree-treenode-selected .tree-outer .site-tree-search-value {
  color: inherit;
}
.ant-tree-treenode-default .tree-outer {
  color: inherit;
}
.ui.multiple.search.dropdown > input.search {
  line-height: 3.214286em !important;
}
.ui.multiple.search.dropdown {
  padding-left: 20px !important;
}
.ui.multiple.search.dropdown .divider {
  padding-left: 0 !important;
}
.ui.multiple.dropdown > .label {
  vertical-align: middle !important;
}
.ui.search.dropdown > .text {
  display: flex;
  align-items: center;
}
.ui.dropdown .menu > .item {
  display: flex !important;
  align-items: center;
}

.select-breadcrumbs {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  cursor: grab;
  align-items: center;
  padding-bottom: 5px;
}

.select-breadcrumb-inner {
  display: flex;
  align-items: center;
}

.select-breadcrumb-root {
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: #888;
  cursor: pointer;
}

.select-breadcrumb {
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: #888;
  cursor: pointer;
}

.select-breadcrumb-active {
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #333;
}
.MuiPickersModal-dialog {
  overflow-x: hidden;
}
.successColor {
  color: #1dbbd4;
}
.errorColor {
  color: #ff9e40;
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MuiAutocomplete-paper .MuiAutocomplete-listbox {
    max-height: 20vh
}

.root{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}
.loading-wrapper{
  height: 100px;
  margin: 0 auto;
}
.table-loading-wrapper{
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
.table-loading-wrapper .loadingio-spinner-ellipsis-0e9htm6lpb7{
  background-color: transparent;
}
.siteLogoLink_img{
  width: 10%;
  min-width: 15px;
  z-index: -1;
  position: absolute;
  bottom: 4%;
  display:  flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #aaa;
}
@-webkit-keyframes ldio-tk8dyrglbc {
  0% { -webkit-transform: translate(6.6000000000000005px,44px) scale(0); transform: translate(6.6000000000000005px,44px) scale(0); }
 25% { -webkit-transform: translate(6.6000000000000005px,44px) scale(0); transform: translate(6.6000000000000005px,44px) scale(0); }
 50% { -webkit-transform: translate(6.6000000000000005px,44px) scale(1); transform: translate(6.6000000000000005px,44px) scale(1); }
 75% { -webkit-transform: translate(44px,44px) scale(1); transform: translate(44px,44px) scale(1); }
100% { -webkit-transform: translate(81.4px,44px) scale(1); transform: translate(81.4px,44px) scale(1); }
}
@keyframes ldio-tk8dyrglbc {
  0% { -webkit-transform: translate(6.6000000000000005px,44px) scale(0); transform: translate(6.6000000000000005px,44px) scale(0); }
 25% { -webkit-transform: translate(6.6000000000000005px,44px) scale(0); transform: translate(6.6000000000000005px,44px) scale(0); }
 50% { -webkit-transform: translate(6.6000000000000005px,44px) scale(1); transform: translate(6.6000000000000005px,44px) scale(1); }
 75% { -webkit-transform: translate(44px,44px) scale(1); transform: translate(44px,44px) scale(1); }
100% { -webkit-transform: translate(81.4px,44px) scale(1); transform: translate(81.4px,44px) scale(1); }
}
@-webkit-keyframes ldio-tk8dyrglbc-r {
  0% { -webkit-transform: translate(81.4px,44px) scale(1); transform: translate(81.4px,44px) scale(1); }
100% { -webkit-transform: translate(81.4px,44px) scale(0); transform: translate(81.4px,44px) scale(0); }
}
@keyframes ldio-tk8dyrglbc-r {
  0% { -webkit-transform: translate(81.4px,44px) scale(1); transform: translate(81.4px,44px) scale(1); }
100% { -webkit-transform: translate(81.4px,44px) scale(0); transform: translate(81.4px,44px) scale(0); }
}
@-webkit-keyframes ldio-tk8dyrglbc-c {
  0% { background: #04b7d2 }
 25% { background: #04b7d2 }
 50% { background: #04b7d2 }
 75% { background: #04b7d2 }
100% { background: #04b7d2 }
}
@keyframes ldio-tk8dyrglbc-c {
  0% { background: #04b7d2 }
 25% { background: #04b7d2 }
 50% { background: #04b7d2 }
 75% { background: #04b7d2 }
100% { background: #04b7d2 }
}
.ldio-tk8dyrglbc div {
 position: absolute;
 width: 22px;
 height: 22px;
 border-radius: 50%;
 -webkit-transform: translate(44px,44px) scale(1);
         transform: translate(44px,44px) scale(1);
 background: #04b7d2;
 -webkit-animation: ldio-tk8dyrglbc 2s infinite cubic-bezier(0,0.5,0.5,1);
         animation: ldio-tk8dyrglbc 2s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-tk8dyrglbc div:nth-child(1) {
 background: #04b7d2;
 -webkit-transform: translate(81.4px,44px) scale(1);
         transform: translate(81.4px,44px) scale(1);
 -webkit-animation: ldio-tk8dyrglbc-r 0.5s infinite cubic-bezier(0,0.5,0.5,1), ldio-tk8dyrglbc-c 2s infinite step-start;
         animation: ldio-tk8dyrglbc-r 0.5s infinite cubic-bezier(0,0.5,0.5,1), ldio-tk8dyrglbc-c 2s infinite step-start;
}.ldio-tk8dyrglbc div:nth-child(2) {
 -webkit-animation-delay: -0.5s;
         animation-delay: -0.5s;
 background: #04b7d2;
}.ldio-tk8dyrglbc div:nth-child(3) {
 -webkit-animation-delay: -1s;
         animation-delay: -1s;
 background: #04b7d2;
}.ldio-tk8dyrglbc div:nth-child(4) {
 -webkit-animation-delay: -1.5s;
         animation-delay: -1.5s;
 background: #04b7d2;
}.ldio-tk8dyrglbc div:nth-child(5) {
 -webkit-animation-delay: -2s;
         animation-delay: -2s;
 background: #04b7d2;
}
.loadingio-spinner-ellipsis-0e9htm6lpb7 {
 width: 110px;
 height: 110px;
 display: inline-block;
 overflow: hidden;
}
.ldio-tk8dyrglbc {
 width: 100%;
 height: 100%;
 position: relative;
 -webkit-transform: translateZ(0) scale(1);
         transform: translateZ(0) scale(1);
 -webkit-backface-visibility: hidden;
         backface-visibility: hidden;
 -webkit-transform-origin: 0 0;
         transform-origin: 0 0; /* see note above */
}
.ldio-tk8dyrglbc div { box-sizing: content-box; }
/* generated by https://loading.io/ */

@media screen and (min-width: 1920px) {
  .loading-wrapper{
    height: 110px;
    margin: 0 auto;
  }
}
.mdl-data-table {
  border: none !important;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 44px;
  z-index: 1;
  position: relative;
}
div.dataTables_scrollHeadInner {
  width: 100% !important;
}
div.dataTables_scrollBody table {
  margin-top: 0;
}
div.dataTables_scrollBody table thead {
  display: none;
}
div.dataTables_wrapper {
  width: 100% !important;
  overflow: auto;
  padding-bottom: 90px;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
  right: 40px;
  top: 14px;
  position: absolute;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  z-index: 9999;
}
div.dataTables_wrapper div.dataTables_filter label {
  position: relative;
  padding-right: 13px;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  width: 100%;
  padding-left: 30px;
  font-size: 16px;
}
div.dataTables_wrapper div.dataTables_filter input:focus {
  outline-offset: 0;
  outline: none;
}
div.dataTables_wrapper div.dataTables_filter label .search-icon {
  position: absolute;
  left: 8px;
  top: -3px;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 10px;
  white-space: nowrap;
  width: 200px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 12px;
  color: #aaa;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 95px;
  left: 0%;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: calc(100% - 90px);
  text-align: center;
  z-index: 9;
}
div.dataTables_wrapper div.dataTables_processing span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  height: calc(100% - 90px);
}
div.dataTables_wrapper div.dataTables_paginate {
  text-align: right;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 24px;
}
div.dataTables_wrapper div.dataTables_paginate a.paginate_button {
  cursor: pointer;
  height: 40px;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_paginate span a.paginate_button {
  line-height: 40px;
  padding: 0 8px;
  color: #999 !important;
}
div.dataTables_wrapper div.dataTables_paginate span a.paginate_button.current {
  color: #000 !important;
}
div.dataTables_wrapper div.mdc-grid.dt-table {
  padding-top: 0;
  padding-bottom: 0;
}
div.dataTables_wrapper div.mdc-grid.dt-table > div.mdc-cell {
  margin-top: 0;
  margin-bottom: 0;
}
div.dataTables_wrapper table.mdc-data-table__table {
  table-layout: auto;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_asc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_desc {
  padding-right: 30px;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th:active {
  outline: none;
}
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_asc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting_desc,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting {
  cursor: pointer;
  position: relative;
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:after,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:before,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:after {
  position: absolute;
  display: block;
  opacity: 0.3;
  right: 1em;
  line-height: 9px;
  font-size: 0.9em;
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:before,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:before {
  bottom: 50%;
  content: "\25B4";
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:after,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after,
div.dataTables_wrapper table.mdc-data-table__table thead tr th.sorting:after {
  top: 50%;
  content: "\25BE";
}
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_asc:before,
div.dataTables_wrapper
  table.mdc-data-table__table
  thead
  tr
  th.sorting_desc:after {
  opacity: 0.8;
}
div.dataTables_length {
  display: none;
}
div.dataTables_wrapper div.mdc-layout-grid {
  margin: 0px;
  padding: 5px;
}
table.dataTable thead {
  border-bottom: 1px solid #ddd;
}
table.dataTable thead th,
table.dataTable tbody td {
  display: table-cell !important;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > th.sorting_disabled {
  padding-right: 50px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #bbb;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3em;
  text-align: left;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  top: 50%;
  display: block;
  opacity: 0.3;
  font-size: 0.9em;
  margin-top: -7px;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  font-family: "Material Icons";
  right: 3.2em;
  content: "arrow_upward";
  -webkit-font-feature-settings: "liga";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: "Material Icons";
  right: 2.5em;
  content: "arrow_downward";
  -webkit-font-feature-settings: "liga";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
table.dataTable tbody tr {
  border-bottom: 1px solid #ddd;
  transition: 0.2s all;
}
table.dataTable tbody tr:nth-child(2n-1) {
  background-color: #f1f1f1;
}
table.dataTable tbody tr:hover {
  background-color: #e9e9e9;
}
table.dataTable tbody tr td {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.6em;
  padding-right: 20px;
  word-break: break-all;
}
table.dataTable tbody tr td.datatable-data-width-100 {
  max-width: 120px;
  word-break: break-word;
}
table.dataTable tbody tr td a {
  text-decoration: none;
  cursor: pointer;
  padding: 8px 0;
}
table.dataTable tbody tr td .main-datatable-info {
  font-weight: bold;
  cursor: pointer;
}
table.dataTable tbody tr td .group-datatable-info {
  font-weight: bold;
}
table.dataTable thead tr th.editable-field,
table.dataTable tbody tr td.editable-field {
  max-width: 250px;
  min-width: 200px;
  width: 240px !important;
  padding-right: 0;
}
table.dataTable thead tr th.tag,
table.dataTable tbody tr td.tag {
  width: 240px !important;
}
table.dataTable tbody tr td.editable-field .MuiGrid-grid-xs-9 {
  max-width: 170px;
}
table.dataTable thead > tr > th:last-child.sorting:before,
table.dataTable thead > tr > th:last-child.sorting:before,
table.dataTable thead > tr > th:last-child.sorting_asc:before,
table.dataTable thead > tr > th:last-child.sorting_desc:before,
table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
  right: 4.1em !important;
}
table.dataTable thead > tr > th:last-child.sorting:after,
table.dataTable thead > tr > th:last-child.sorting:after,
table.dataTable thead > tr > th:last-child.sorting_asc:after,
table.dataTable thead > tr > th:last-child.sorting_desc:after,
table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
  right: 3.4em !important;
}
.dataTables_scrollHead {
  padding-right: 30px;
  color: #aaa;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 3.6em;
  text-align: left;
}
.MuiChip-colorPrimary {
  color: #05b0ca !important;
  background-color: #08b9d413 !important;
}
.MuiChip-colorSecondary {
  color: #777 !important;
  background-color: rgba(177, 177, 177, 0.1) !important;
}
.paginate_button.previous,
.paginate_button.next {
  position: relative;
  width: 30px;
  overflow: hidden;
  text-indent: 9999px;
}
.paginate_button.previous::before {
  position: absolute;
  display: block;
  width: 30px;
  height: 40px;
  font-size: 2em;
  color: #ccc !important;
  text-indent: -9999px;
  font-family: "Material Icons";
  content: "chevron_left";
  -webkit-font-feature-settings: "liga";
}
.paginate_button.next::before {
  position: absolute;
  display: block;
  width: 30px;
  height: 40px;
  font-size: 2em;
  color: #ccc !important;
  text-indent: -9999px;
  font-family: "Material Icons";
  content: "chevron_right";
  -webkit-font-feature-settings: "liga";
}
.MuiInputBase-input {
  font-size: 14px !important;
}
.resend-btn-td,
.reset-btn-td {
  min-width: 140px;
}
.resend-btn-td span,
.reset-btn-td span {
  margin-bottom: 0 !important;
  cursor: pointer;
  padding: 5px 15px !important;
  font-size: 12px;
  color: #05b0ca !important;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #05b0ca;
  width: auto !important;
  transition: 0.3s;
}
.resend-btn-td span:hover,
.reset-btn-td span:hover {
  background-color: #05b0ca19;
}
div.dataTables_wrapper div.dataTables_paginate > span {
  display: flex;
  align-items: center;
}
.task_name {
  font-weight: bold;
  min-width: 110px;
  max-width: 180px;
}
.task_name_task {
  font-weight: bold;
  min-width: 100px;
}
.serial_number_task{
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;
}
.date_task{
  white-space: nowrap;
  overflow: hidden;
}
#device-eligibility-table {
  margin-bottom: 8px;
}
table#device-eligibility-table tbody tr td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#device-eligibility-table .MuiSvgIcon-colorPrimary {
  color: #1dbbd4 !important;
}
#device-eligibility-table .MuiSvgIcon-colorSecondary {
  color: #ddd !important;
}
.status-connected {
  color: #1dbbd4 !important;
}
.status-disconnected {
  color: #ff9e40 !important;
}
.status-detecting {
  color: #aaa !important;
}
.status-update_fail {
  color: #ff9e40 !important;
}
.status-update_successful {
  color: #1dbbd4 !important;
}
.peripheral-type {
  font-weight: 500;
}
#device-eligibility-table_wrapper {
  padding-bottom: 0;
}
.dt-checkboxes-select-all {
  padding-left: 44px !important;
  cursor: auto !important;
  width: 30px !important; /* remove this padding-left if selected-all input is shown*/
  padding-left: 0px !important; /* remove this padding-left if selected-all input is shown*/
}
.dt-checkboxes-select-all input[type="checkbox"] {
  -webkit-appearance: none !important;
          appearance: none !important;
  display: none !important;
  font-size: 24px;
  color: #aaa;
}
.dt-checkboxes-select-all::before,
.dt-checkboxes-select-all::after {
  display: none !important;
}
.dt-checkboxes-select-all input[type="checkbox"]::before {
  content: "check_box_outline_blank";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
}
.dt-checkboxes-select-all input[type="checkbox"]:checked::before {
  content: "check_box";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
}

.dataTables_scrollHead table.dt-checkboxes-select th.select-checkbox {
  width: 34px !important;
  padding-left: 24px !important;
  padding-right: 28px;
}

.dataTables_scrollBody table.dt-checkboxes-select td.select-checkbox {
  width: 50px !important;
  padding-left: 24px !important;
}

.dataTables_scrollHead table.dt-checkboxes-select th.select-checkbox:before,
.dataTables_scrollHead table.dt-checkboxes-select th.select-checkbox:after {
  display: none;
}

#device-eligibility-table_wrapper th:nth-child(2),
#device-eligibility-table_wrapper td:nth-child(2) {
  width: 50% !important;
}
.owner_name {
  display: block;
}
table.dataTable thead > tr > th.serial_number,
table.dataTable tbody tr td.serial_number {
  width: 15%;
  min-width: 170px !important;
  max-width: 350px !important;
}
table.dataTable thead > tr > th.dt-checkboxes-cell,
table.dataTable tbody tr td.dt-checkboxes-cell {
  width: 10%;
  min-width: 100px !important;
  max-width: 100px !important;
}
table.dataTable thead > tr > th.group_serial_number,
table.dataTable tbody tr td.group_serial_number {
  width: 18%;
  min-width: 200px !important;
  max-width: 350px !important;
}
table.dataTable thead > tr > th.group_serial_number_add,
table.dataTable tbody tr td.group_group_serial_number_add {
  width: 25% !important;
}
table.dataTable thead > tr > th.group_os_type_add,
table.dataTable tbody tr td.group_os_type_add {
  width: 15% !important;
  min-width: 150px !important;
  max-width: 150px !important;
}
table.dataTable thead > tr > th.group_ora_version_add,
table.dataTable tbody tr td.group_ora_version_add {
  width: 30% !important;
  min-width: 250px !important;
  max-width: 250px !important;
}
table.dataTable thead > tr > th.group_os,
table.dataTable tbody tr td.group_os {
  width: 10%;
  min-width: 150px !important;
  max-width: 250px !important;
}
table.dataTable thead > tr > th.group_ora_version,
table.dataTable tbody tr td.group_ora_version {
  width: 15%;
  min-width: 200px !important;
  max-width: 350px !important;
}
table.dataTable thead > tr > th.nubis_version,
table.dataTable tbody tr td.nubis_version {
  width: 160px !important;
}
table.dataTable thead > tr > th.os_type,
table.dataTable tbody tr td.os_type {
  width: 40px !important;
  min-width: 40px !important;
}
table.dataTable thead > tr > th.ora_version,
table.dataTable tbody tr td.ora_version {
  width: 13%;
  min-width: 100px !important;
}
.os_area {
  display: flex;
  align-items: center;
  margin-right: 0 !important;
}
table.dataTable thead > tr > th.power,
table.dataTable tbody tr td.power {
  width: 13%;
  max-width: 140px !important;
  min-width: 80px !important;
}
table.dataTable thead > tr > th.power,
table.dataTable tbody tr td.security {
  width: 13%;
  max-width: 140px !important;
  min-width: 80px !important;
}
table.dataTable thead > tr > th.owners,
table.dataTable tbody tr td.owners {
  width: 15% !important;
  min-width: 110px !important;
  max-width: 200px !important;
}
table.dataTable thead > tr > th.status,
table.dataTable tbody tr td.status {
  width: 10%;
  min-width: 150px !important;
  padding-left: 35px;
}
table.dataTable thead > tr > th.status {
  padding-left: 40px;
}
table.dataTable thead > tr > th.task_name,
table.dataTable tbody tr td.task_name {
  width: 350px !important;
}
table.dataTable thead > tr > th.date,
table.dataTable tbody tr td.date {
  width: 200px !important;
}
table.dataTable thead > tr > th.groups,
table.dataTable tbody tr td.groups {
  width: 20%;
  max-width: 480px !important;
  min-width: 200px !important;
}
table.device-command-datatable thead tr th:first-child,
table.device-command-datatable thead tr th:first-child.sorting_asc,
table.device-command-datatable tbody tr td:first-child {
  width: 40px !important;
  padding-right: 20px !important;
}
table.device-command-datatable thead tr th:first-child::before,
table.device-command-datatable thead tr th:first-child::after {
  display: none;
}
table.device-command-datatable thead tr th:nth-child(2),
table.device-command-datatable tbody tr td:nth-child(2) {
  min-width: 200px !important;
}
.MuiTableCell-alignRight {
  max-width: 530px;
}
div#device-eligibility-table_wrapper {
  position: relative;
  padding-bottom: 50px;
}
div#device-eligibility-table_wrapper div.dataTables_info {
  bottom: 10px;
}

div#device-eligibility-table_wrapper div.dataTables_info .select-info {
  display: none;
}
table#alarm-table thead tr th:first-child,
table#alarm-table tbody tr td:first-child {
  width: 300px;
}

thead tr th.sorting_disabled:before,
thead tr th.sorting_disabled:after {
  content: "" !important;
}

th.file-name,
td.file-name {
  width: 50% !important;
}

td.file-name {
  font-weight: bolder;
}

td.file-name .MuiFormControl-root {
  width: 95% !important;
}

td.file-name .folder-link input.Mui-disabled {
  cursor: pointer;
}

td.file-name .MuiBox-root > svg,
td.file-name .folder-link > svg {
  margin-right: 18px !important;
  cursor: auto;
  cursor: initial;
}

td.td-center {
  text-align: center;
  padding-right: 60px !important;
}

td.td-center a {
  text-decoration: underline !important;
}

th.reset-status-td {
  width: 160px;
}

#alarm-policy-deploy-table thead tr th:first-child,
#alarm-policy-deploy-table tbody tr td:first-child {
  padding-left: 32px !important;
}

.datatable-with-title-searchbar-wrap {
  position: relative;
}
.datatable-with-title-searchbar-wrap h5 {
  margin-left: 24px;
  top: 18px;
}

.datatable-with-title-searchbar-wrap table {
  margin-top: 20px !important;
}

.datatable-with-title-searchbar-wrap .dataTables_wrapper {
  padding-bottom: 20px !important;
}

.datatable-with-title-searchbar-wrap .dataTables_wrapper div.dataTables_info {
  bottom: -10px !important;
}

.datatable-with-title-searchbar-wrap
  .dataTables_wrapper
  div.dataTables_paginate {
  padding-top: 10px;
}

div.task-device-table-full-width {
  overflow-x: hidden;
  margin-bottom: -30px;
}

div.task-device-table-full-width > div {
  margin: 0 !important;
  top: 0px;
}

div.task-device-table-full-width div.dataTables_wrapper div.dataTables_filter {
  top: 4px;
}

div.task-device-table-full-width > div table.dataTable tbody tr td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div.task-device-table-full-width .mdl-data-table {
  width: 100% !important;
}

div.task-device-table-full-width .dataTables_scrollHead {
  padding-right: 0;
}

div.task-device-table-full-width > div table.dataTable thead tr th,
div.task-device-table-full-width > div table.dataTable tbody tr td {
  min-width: 0 !important;
  min-width: initial !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  thead
  tr
  th.select-checkbox {
  min-width: 0 !important;
  min-width: initial !important;
  width: 102px !important;
  padding-right: 20px !important;
  padding-left: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  thead
  tr
  th.serial-number,
div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.serial-number {
  width: 290px !important;
  padding-right: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width > div table.dataTable thead tr th.os-type,
div.task-device-table-full-width > div table.dataTable tbody tr td.os-type {
  width: 200px !important;
  padding-right: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width > div table.dataTable thead tr th.egilibility,
div.task-device-table-full-width > div table.dataTable tbody tr td.egilibility {
  width: 200px !important;
  padding-right: 40px !important;
  box-sizing: border-box !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.egilibility
  .MuiSvgIcon-root {
  margin-top: 4px;
}

div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.egilibility
  .MuiSvgIcon-colorPrimary {
  color: #1dbbd4 !important;
}

div.task-device-table-full-width
  > div
  table.dataTable
  tbody
  tr
  td.egilibility
  .MuiSvgIcon-colorSecondary {
  color: #ddd !important;
}

.select-info {
  display: none;
}
.tableIcon {
  width: 33px;
  height: 23px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.power .tableIcon svg {
  display: flex;
  align-items: center;
  width: 19px !important;
  height: 19px !important;
}
.security .tableIcon svg {
  display: flex;
  align-items: center;
  width: 17px !important;
  height: 17px !important;
}

table.dataTable tbody tr td.checkbox .disabledCheckboxOuter {
  padding: 9px 10px;
}
table.dataTable tbody tr td.checkbox .disabledCheckbox {
  width: 15px;
  height: 15px;
  border: 2px solid #ddd;
  margin: 0;
  background-color: #fbfbfb;
  border-radius: 3px;
  cursor: not-allowed;
}
table.dataTable tbody tr td.appVersionManagement .appVersionManagementBtn {
  width: 200px;
  color: #1dbbd4;
  text-align: center;
  border: 1px solid #1dbbd4;
  padding: 4px 10px;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
}

table.dataTable tbody tr td.stopApplication .stopApplicationOuter .stopBtn {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #f98746;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
table.dataTable tbody tr td.stopApplication .stopApplicationOuter .stopBtn .square {
  width: 8px;
  height: 8px;
  background-color: #fff;
}
table.dataTable tbody tr td.stopApplication .stopApplicationOuter .stopBtn.disabled {
  background-color: #ccc;
}

@media screen and (max-width: 1680px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 50px;
  }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 1.5em !important;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 3.1em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 2.3em !important;
  }
  table#alarm-table thead tr th:nth-child(2),
  table#alarm-table tbody tr td:nth-child(2) {
    max-width: 600px;
  }
}

@media screen and (max-width: 1440px) {
  table.dataTable thead > tr > th.sorting:before,
  table.dataTable thead > tr > th.sorting_asc:before,
  table.dataTable thead > tr > th.sorting_desc:before,
  table.dataTable thead > tr > th.sorting_asc_disabled:before,
  table.dataTable thead > tr > th.sorting_desc_disabled:before {
    right: 1.2em !important;
  }
  table.dataTable thead > tr > th.sorting:after,
  table.dataTable thead > tr > th.sorting_asc:after,
  table.dataTable thead > tr > th.sorting_desc:after,
  table.dataTable thead > tr > th.sorting_asc_disabled:after,
  table.dataTable thead > tr > th.sorting_desc_disabled:after {
    right: 0.5em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 2.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 2em !important;
  }
  table#alarm-table thead tr th:nth-child(2),
  table#alarm-table tbody tr td:nth-child(2) {
    max-width: 370px;
  }
}
@media screen and (max-width: 1279px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 40px;
  }
  table.dataTable tbody tr td.editable-field {
    max-width: 200px;
    min-width: 150px;
  }
  table.dataTable thead > tr > th.sorting:before,
  table.dataTable thead > tr > th.sorting_asc:before,
  table.dataTable thead > tr > th.sorting_desc:before,
  table.dataTable thead > tr > th.sorting_asc_disabled:before,
  table.dataTable thead > tr > th.sorting_desc_disabled:before {
    right: 2em !important;
  }
  table.dataTable thead > tr > th.sorting:after,
  table.dataTable thead > tr > th.sorting_asc:after,
  table.dataTable thead > tr > th.sorting_desc:after,
  table.dataTable thead > tr > th.sorting_asc_disabled:after,
  table.dataTable thead > tr > th.sorting_desc_disabled:after {
    right: 1.1em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting:before,
  table.dataTable thead > tr > th:last-child.sorting_asc:before,
  table.dataTable thead > tr > th:last-child.sorting_desc:before,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:before {
    right: 2.7em !important;
  }
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting:after,
  table.dataTable thead > tr > th:last-child.sorting_asc:after,
  table.dataTable thead > tr > th:last-child.sorting_desc:after,
  table.dataTable thead > tr > th:last-child.sorting_asc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting_desc_disabled:after {
    right: 1.8em !important;
  }
  table#alarm-table thead tr th:nth-child(2),
  table#alarm-table tbody tr td:nth-child(2) {
    max-width: 300px;
  }
}
@media screen and (max-width: 1024px) {
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead > tr > th:last-child.sorting:before {
    right: 3.1em !important;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after,
  table.dataTable thead > tr > th:last-child.sorting:after {
    right: 2.4em !important;
  }
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 80px;
  }
}
@media screen and (max-width: 768px) {
  div.dataTables_wrapper div.dataTables_filter input {
    width: 80%;
  }
}
@media screen and (max-width: 670px) {
  div.dataTables_wrapper div.dataTables_filter {
    min-width: 100px;
    max-width: 150px;
    width: 40%;
  }
}
@media screen and (max-width: 599px) {
  div.dataTables_wrapper div.dataTables_filter {
    right: 50%;
    margin-right: -106.5px;
    width: 100%;
    max-width: 230px;
  }
  div.dataTables_wrapper div.dataTables_filter input {
    width: 100%;
  }
}

.dateTimePicker-wrapper{
    margin-top: 8px;
}

.dateTimePicker-wrapper .MuiInput-root{
    overflow: visible !important;
    overflow: initial !important;
    max-width: 300px;
}

.dateTimePicker-wrapper .MuiTypography-h3{
    line-height: 1em !important;
}

.MuiPickersBasePicker-container .MuiPaper-rounded{
    padding: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}

.MuiPickersToolbarButton-toolbarBtn{ 
    margin-bottom: 0 !important;
    height: auto !important;
}

.MuiPickersBasePicker-container .MuiButton-root:hover{
    background-color: none !important;
}

.MuiPickersToolbarText-toolbarTxt{
    color: rgba(255,255,255,0.6) !important;
}

.MuiPickersToolbarText-toolbarBtnSelected{
    color: #fff !important;
}

.MuiPickersBasePicker-container .MuiTouchRipple-root{
    display: none !important;
}

.MuiPickersBasePicker-container .MuiSvgIcon-root{
    fill: #fff !important;
}

.MuiTabs-indicator {
    background-color: #fff !important;
}

.MuiPickersBasePicker-pickerView{
    padding-bottom: 20px !important;
}

.MuiPickersDay-daySelected,
.MuiPickersClockNumber-clockNumberSelected {
    color: #fff !important;
}

.MuiPickersCalendarHeader-switchHeader .MuiSvgIcon-root{
    fill: #000 !important;
}
