.dateTimePicker-wrapper{
    margin-top: 8px;
}

.dateTimePicker-wrapper .MuiInput-root{
    overflow: initial !important;
    max-width: 300px;
}

.dateTimePicker-wrapper .MuiTypography-h3{
    line-height: 1em !important;
}

.MuiPickersBasePicker-container .MuiPaper-rounded{
    padding: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}

.MuiPickersToolbarButton-toolbarBtn{ 
    margin-bottom: 0 !important;
    height: auto !important;
}

.MuiPickersBasePicker-container .MuiButton-root:hover{
    background-color: none !important;
}

.MuiPickersToolbarText-toolbarTxt{
    color: rgba(255,255,255,0.6) !important;
}

.MuiPickersToolbarText-toolbarBtnSelected{
    color: #fff !important;
}

.MuiPickersBasePicker-container .MuiTouchRipple-root{
    display: none !important;
}

.MuiPickersBasePicker-container .MuiSvgIcon-root{
    fill: #fff !important;
}

.MuiTabs-indicator {
    background-color: #fff !important;
}

.MuiPickersBasePicker-pickerView{
    padding-bottom: 20px !important;
}

.MuiPickersDay-daySelected,
.MuiPickersClockNumber-clockNumberSelected {
    color: #fff !important;
}

.MuiPickersCalendarHeader-switchHeader .MuiSvgIcon-root{
    fill: #000 !important;
}